import React from "react";

const CardImageTop = ({ children }) => {

  return (
    <div className={`flex flex-col-reverse md:flex-col`}>
        {children}
    </div>
  )
}

export default CardImageTop