import React from "react";

const ContainerRows = ({ children }) => {

  return (
    <div className={`flex flex-col md:flex-row md:space-x-8`}>
        {children}
    </div>
  )
}

export default ContainerRows