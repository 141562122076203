import * as React from 'react'
import Layout from '../components/layout'
import ContainerRows from '../components/container-rows'
import CardImageTop from '../components/card-image-top'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Guides = () => {

  return (
    <Layout>

      <section className='py-20 px-8 md:px-12 bg-slate-700'>
        <div className='max-w-6xl xl:flex xl:flex-col xl:mx-auto'>
          <h1 className='text-white text-4xl md:text-5xl'>Guides and reviews</h1>
          <p className='max-w-4xl text-slate-200 text-xl md:text-2xl'>
            Wouldn’t it be great if there was something like Wirecutter 
            or Consumer Reports for climate action? We thought so too.  
            That’s why we spent hundreds of hours making these guides and reviews.
          </p>
        </div>
      </section>

      <section id="electrify" className='pt-20 pb-10 px-8 md:px-12 xl:px-0 max-w-6xl xl:flex xl:flex-col xl:mx-auto'>
        <div>
          <h2>Electrify everything ⚡</h2>
          <p className='max-w-4xl'>
            Today most cars and homes run on fossil fuels that release CO2 and other 
            pollutants into the atmosphere. Learn how you can save money and cut carbon 
            by switching to cleaner, more efficient electric alternatives. 
          </p>
        </div>
        <div className='-ml-4'>
          <ContainerRows>
              <CardImageTop>
                <Link to="/heat-pump-savings">
                  <StaticImage 
                    src="../images/home-images/heat-pump-guide.png" 
                    alt="Heat pump image" 
                  />
                </Link>
                <Link 
                  className='my-4 text-2xl sm:text-xl font-extrabold sm:font-normal no-underline'
                  to="/heat-pump-savings"
                >
                  How to Save Money and Energy by Switching to a Heat Pump
                </Link>
              </CardImageTop>
              <CardImageTop>
                <Link to="/heat-pump-water-heater-buyers-guide">
                  <StaticImage 
                    src="../images/home-images/heat-pump-water-heater-guide-feature.png" 
                    alt="Heat pump water heater image" 
                  />
                </Link>
                <Link 
                  className='my-4 text-2xl sm:text-xl font-extrabold sm:font-normal no-underline'
                  to="/heat-pump-water-heater-buyers-guide"
                >
                  Heat Pump Water Heater Buyers Guide
                </Link>
              </CardImageTop>
              <CardImageTop>
                <Link to="/tankless-water-heater-buyers-guide">
                  <StaticImage 
                    src="../images/home-images/tankless-guide.png" 
                    alt="Tankless water heater" 
                  />
                </Link>
                <Link 
                  className='my-4 text-2xl sm:text-xl font-extrabold sm:font-normal no-underline'
                  to="/led-savings"
                >
                  Tankless Water Heater Buyer’s Guide
                </Link>
              </CardImageTop>
            </ContainerRows>
          </div>
      </section>

      <section id="leds" className='pb-20 px-6 md:px-12 xl:px-0 max-w-6xl xl:flex xl:flex-col xl:mx-auto'>
        <div>
          <h2>Install LED lights 💡</h2>
          <p className='max-w-4xl'>
          Ok, time to confess. At first, we were a bit skepitcal of LEDs as an effective way to cut carbon. 
          We were wrong. Not only is this easy weekend project a great climate solution, 
          but changing all your bulbs can save you $2,000 - $4,000 over 20 years. 
          Not bad for a couple hours of work.
          </p>
        </div>
        <div className='-ml-4'>
          <ContainerRows>
              <CardImageTop>
                <Link to="/led-lighting-buyers-guide">
                  <StaticImage 
                    src="../images/home-images/led-guide.png" 
                    alt="LED lights image" 
                  />
                </Link>
                <Link 
                  className='my-4 text-2xl sm:text-xl font-extrabold sm:font-normal no-underline'
                  to="/led-lighting-buyers-guide"
                >
                  LED Lighting Buyer’s Guide
                </Link>
              </CardImageTop>
              <CardImageTop>
                <Link to="/led-savings">
                  <StaticImage 
                    src="../images/home-images/led-savings.png" 
                    alt="LED savings image" 
                  />
                </Link>
                <Link 
                  className='my-4 text-2xl sm:text-xl font-extrabold sm:font-normal no-underline'
                  to="/led-savings"
                >
                  How Much Can You Save by Switching to LEDs?
                </Link>
              </CardImageTop>
              <CardImageTop>
                <Link to="/led-temperature-and-color-guide">
                  <StaticImage 
                    src="../images/home-images/led-color-and-temperature-guide.png" 
                    alt="LED temperature and color guide" 
                  />
                </Link>
                <Link 
                  className='my-4 text-2xl sm:text-xl font-extrabold sm:font-normal no-underline'
                  to="/led-temperature-and-color-guide"
                >
                  LED Temperature and Color Buyer’s Guide
                </Link>
              </CardImageTop>
            </ContainerRows>
          </div>
      </section>

      <section id="leds" className='pb-20 px-6 md:px-12 xl:px-0 max-w-6xl xl:flex xl:flex-col xl:mx-auto'>
        <div>
          <h2>Make your next water heater electric 🛁</h2>
          <p className='max-w-4xl'>
          Water heaters use <b>a lot</b> of energy. And most of them run on fossil fuels like natural gas, propane and fuel oil. 
          Learn how you can save between $200-$600 per year by switching to a hybrid (heat pump) water heater 
          or a tankless electric water heater.
          </p>
        </div>
        <div className='-ml-4'>
          <ContainerRows>
              <CardImageTop>
                <Link to="/heat-pump-water-heater-buyers-guide">
                  <StaticImage 
                    src="../images/home-images/heat-pump-water-heater-guide-feature.png" 
                    alt="Heat pump water heater image" 
                  />
                </Link>
                <Link 
                  className='my-4 text-2xl sm:text-xl font-extrabold sm:font-normal no-underline'
                  to="/heat-pump-water-heater-buyers-guide"
                >
                  Heat Pump Water Heater Buyers Guide
                </Link>
              </CardImageTop>
              <CardImageTop>
                <Link to="/heat-pump-water-heater-reviews">
                  <StaticImage 
                    src="../images/home-images/best-heat-pump-water-heater.png" 
                    alt="Best heat pump water heater image" 
                  />
                </Link>
                <Link 
                  className='my-4 text-2xl sm:text-xl font-extrabold sm:font-normal no-underline'
                  to="/heat-pump-water-heater-reviews"
                >
                  Heat Pump Water Heater Reviews
                </Link>
              </CardImageTop>
              <CardImageTop>
                <Link to="/tankless-water-heater-reviews">
                  <StaticImage 
                    src="../images/home-images/tankless-guide.png" 
                    alt="LED temperature and color guide" 
                  />
                </Link>
                <Link 
                  className='my-4 text-2xl sm:text-xl font-extrabold sm:font-normal no-underline'
                  to="/tankless-water-heater-reviews"
                >
                  The Best Tankless Water Heater (Reviews)
                </Link>
              </CardImageTop>
            </ContainerRows>
          </div>
      </section>

    </Layout>
  )

}

export default Guides